document.addEventListener("DOMContentLoaded", function () {
  const closeSVG = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.88242 10.1216L13.1854 15.4246C13.4668 15.706 13.8485 15.8641 14.2464 15.8641C14.6444 15.8641 15.026 15.706 15.3074 15.4246C15.5888 15.1432 15.7469 14.7616 15.7469 14.3636C15.7469 13.9657 15.5888 13.584 15.3074 13.3026L10.0024 7.99962L15.3064 2.69662C15.4457 2.55729 15.5561 2.39189 15.6315 2.20987C15.7068 2.02785 15.7456 1.83277 15.7455 1.63577C15.7455 1.43877 15.7066 1.24371 15.6312 1.06172C15.5558 0.879735 15.4452 0.714389 15.3059 0.575122C15.1666 0.435855 15.0012 0.325396 14.8192 0.25005C14.6371 0.174704 14.4421 0.135948 14.2451 0.135995C14.0481 0.136041 13.853 0.174889 13.671 0.25032C13.489 0.325752 13.3237 0.43629 13.1844 0.575622L7.88242 5.87862L2.57942 0.575622C2.44111 0.432293 2.27565 0.317943 2.09268 0.239244C1.90971 0.160546 1.7129 0.119074 1.51374 0.11725C1.31457 0.115426 1.11703 0.153286 0.932653 0.22862C0.748274 0.303955 0.580745 0.415255 0.43984 0.556027C0.298935 0.696799 0.187477 0.864224 0.111969 1.04853C0.0364604 1.23284 -0.00158556 1.43034 5.06168e-05 1.62951C0.00168679 1.82868 0.0429722 2.02553 0.121498 2.20857C0.200024 2.39161 0.314218 2.55718 0.457417 2.69562L5.76242 7.99962L0.458417 13.3036C0.315218 13.4421 0.201025 13.6076 0.122499 13.7907C0.0439726 13.9737 0.00268672 14.1706 0.00105054 14.3697C-0.000585633 14.5689 0.0374603 14.7664 0.112969 14.9507C0.188477 15.135 0.299935 15.3024 0.44084 15.4432C0.581745 15.584 0.749274 15.6953 0.933653 15.7706C1.11803 15.846 1.31557 15.8838 1.51474 15.882C1.7139 15.8802 1.91071 15.8387 2.09368 15.76C2.27665 15.6813 2.44211 15.5669 2.58042 15.4236L7.88242 10.1216Z" fill="#011B31"/>
    </svg>`;

  const closeIcon = document.querySelector(
    ".wp-block-navigation__responsive-container-close svg"
  );

  if (closeIcon) {
    closeIcon.outerHTML = closeSVG;
  }

  const searchSVG = ` <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.0002 19.0002L14.6572 14.6572M14.6572 14.6572C15.4001 13.9143 15.9894 13.0324 16.3914 12.0618C16.7935 11.0911 17.0004 10.0508 17.0004 9.00021C17.0004 7.9496 16.7935 6.90929 16.3914 5.93866C15.9894 4.96803 15.4001 4.08609 14.6572 3.34321C13.9143 2.60032 13.0324 2.01103 12.0618 1.60898C11.0911 1.20693 10.0508 1 9.00021 1C7.9496 1 6.90929 1.20693 5.93866 1.60898C4.96803 2.01103 4.08609 2.60032 3.34321 3.34321C1.84288 4.84354 1 6.87842 1 9.00021C1 11.122 1.84288 13.1569 3.34321 14.6572C4.84354 16.1575 6.87842 17.0004 9.00021 17.0004C11.122 17.0004 13.1569 16.1575 14.6572 14.6572Z" stroke="#4E4E4E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`;

  const searchIcon = document.querySelector(".wp-block-search__button has-icon svg");

  if (searchIcon) {
    searchIcon.outerHTML = searchSVG;
  }

  // Column Nav

  // Select all submenu containers
    const submenuContainers = document.querySelectorAll(".wp-block-navigation__submenu-container");

    submenuContainers.forEach(submenu => {
        // Find the element with class 'column'
        const columnElement = submenu.querySelector(".column");

        if (columnElement) {
            // Create two new divs to hold the two columns
            const column1 = document.createElement("div");
            column1.classList.add("submenu-column-1", "submenu-column");
            const column2 = document.createElement("div");
            column2.classList.add("submenu-column-2", "submenu-column");

            let beforeColumn = true;

            // Loop through all child elements of the submenu
            Array.from(submenu.children).forEach(child => {
                if (child === columnElement) {
                    beforeColumn = false;
                    return; // Skip the column element itself
                }

                if (beforeColumn) {
                    column1.appendChild(child);
                } else {
                    column2.appendChild(child);
                }
            });

            // Create a div for the vertical line
            const verticalLine = document.createElement("div");
            verticalLine.classList.add("vertical-line");

            // Clear the original submenu and append the two columns
            submenu.innerHTML = "";
            submenu.appendChild(column1);
            submenu.appendChild(verticalLine);
            submenu.appendChild(column2);
        }
    });

  const qtyInputsBtns = document.querySelectorAll(".qty-input");
  const qtyInput = document.querySelector(".product .quantity .qty");
  qtyInputsBtns.forEach((qtyInputBtn) => {
    const decreaseButton =
      qtyInputBtn.parentNode.querySelector("#decrease-qty");
    const increaseButton =
      qtyInputBtn.parentNode.querySelector("#increase-qty");

    // Add event listeners to the buttons
    increaseButton.addEventListener("click", (event) => {
      event.preventDefault();
      qtyInput.value = parseInt(qtyInput.value) + 1;
    });
    decreaseButton.addEventListener("click", (event) => {
      event.preventDefault();
      if (qtyInput.value > 1) {
        qtyInput.value = parseInt(qtyInput.value) - 1;
      }
    });
  });

  // Add read more button to product description
  const descriptionPanel = document.querySelector("#tab-description");
  if (descriptionPanel) {
    if (descriptionPanel.scrollHeight > 800) {
      const showMoreButton = document.createElement("button");
      const showMoreButtonClasses = ["button", "button-show-more"];
      showMoreButton.classList.add(...showMoreButtonClasses);
      showMoreButton.textContent = "Show More";
      showMoreButton.addEventListener("click", () => {
        descriptionPanel.classList.toggle("open");
        if (descriptionPanel.classList.contains("open")) {
          showMoreButton.style.display = "none";
        } else {
          showMoreButton.style.display = "block";
        }
      });
      descriptionPanel.parentNode.appendChild(showMoreButton);
    }
  }

  // MiniCart into nav menu

  const miniCartElement = document.querySelector('[data-block-name="woocommerce/mini-cart"]');
  const headerContainer = document.querySelector('.headerContainer .wp-block-navigation__container');
  const menuButton = document.querySelector('.headerContainer .wp-block-navigation__container .button-menu');
  headerContainer.insertBefore(miniCartElement, menuButton);
  miniCartElement.style.display = "block";
});
